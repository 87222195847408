<template>
  <div class="dgsa-home">
    <div class="das-banner">
      <div class="dgs-banner-txt">
        <div class="banner-txt-title">欢迎来到DGSAdvisor 危险品安全顾问平台</div>
        <div class="banner-txt-value">
          本平台为企业管理危险品提供专业数字化支持，由德国GBK 公司结合多年的危险品合规服务经验 ，为企业危险品管理提供在线快捷，有效的专业服务。特别借鉴了德国危险货物安全顾问条例，为企业危险品相关审核，培训等提供一体化解决方案。
        </div>
      </div>
    </div>
    <div class="dgs-module">
      <!-- <el-carousel
        indicator-position="outside"
        arrow="never"
        v-loading="loading"
        v-show="infoData.length"
      >
        <el-carousel-item
          v-for="(item, subscript) in infoData"
          :key="subscript"
        >
          <div class="module-list">
            <div
              class="module-item"
              :style="`margin-right: ${index === 3 ? '0' : '42px'};`"
              v-for="(value, index) in item"
              :key="index"
            >
              <div class="item-title">
                资料下载：{{ type_list[value.ftype] }}
              </div>
              <div class="item-value">
                {{ value.file_name }}
              </div>
              <div class="item-btn">
                <span @click="moduleDownload(value)" class="cursor"
                  >立即下载</span
                >
                <i @click="moduleDownload(value)" class="el-icon-download cursor"></i>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
      <div class="module-title">我们的服务</div>
      <div class="module-value">
        我们以专业、诚信、沟通为理念，为客户提供最满意的服务
      </div>
    </div>
    <div class="dgs-serve">
      <div class="serve-list">
        <div class="serve-item" v-for="(val, ind) in serve_list" :key="ind">
          <img
            :src="val.img"
            :style="`width: ${val.img_width}px;height: ${val.img_height}px;`"
            alt=""
          />
          <div class="setve-horizontal"></div>
          <div class="setve-title">{{ val.title }}</div>
          <div class="setve-title1">{{val.title1}}</div>
          <div class="setve-btn cursor">
            <div @click="routerPush(val.ftype)">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <downloadDialog
      :dialogVisible="dialogVisible"
      :downloadData="downloadData"
      @closeBtn="closeBtn"
    />
  </div>
</template>

<script>
import downloadDialog from "~gbk/components/dasa/downloadDialog";
export default {
  name: "dgsaHome",
  metaInfo: {
    title: "DGSA危险品安全顾问",
  },
  components: {
    downloadDialog,
  },
  data() {
    return {
      serve_list: [
        {
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/DGSAsds.png",
          img_width: 66,
          img_height: 64,
          title: "产品管理",
          title1:"为企业提供产品安全说明书SDS与标签管理，以及应急电话，危化品登记，新物质注册等一站式服务",
          ftype: "1",
        },
        {
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/DGSAtraining.png",
          img_width: 66,
          img_height: 64,
          title: "培训",
          title1:"为企业提供培训计划与培训方案，满足危险品相关人员培训要求\n",
          ftype: "2",
        },
        {
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/DGSAaudit.png",
          img_width: 66,
          img_height: 57,
          title: "审核清单",
          title1:"为企业提供危险品内部管理以及供应商管理等审核清单check list",
          ftype: "3",
        },
        {
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/DGSAconsult.png",
          img_width: 51,
          img_height: 62,
          title: "咨询报告",
          title1: "为企业提供危险品管理手册等合规解决方案",
          ftype: "4",
        },
        {
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/DGSAdownload.png",
          img_width: 60,
          img_height: 62,
          title: "法规标准",
          title1:"为企业提供危险品相关法规与标准下载管理服务",
          ftype: "5",
        },
        {
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/DGSAother.png",
          img_width: 52,
          img_height: 65,
          title: "咨询台helpdesk",
          title1: "为企业提供危险品咨询台helpdesk，为企业解决日常危险品合规事宜",
          ftype: "6",
        },
      ], //服务列表
      dialogVisible: false, //下载
      loading: false,
      type_list: {
        1: "产品管理",
        2: "培训",
        3: "审核清单",
        4: "咨询报告",
        5: "法规标准",
        6: "咨询台helpdesk",
      },
      infoData: [],
      downloadData: { documentList: [], imgList: [], id: "" }, //下载数据
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 模块内容下载
    moduleDownload(value) {
      if (!this.USER_INFO) {
        this.$router.push({
          path: "/sign",
        });
        return false;
      }
      this.downloadData = { documentList: [], imgList: [], id: value.id };
      let url_list = value.file_url ? JSON.parse(value.file_url) : [];
      url_list.forEach((item) => {
        if (item.type === "img") {
          this.downloadData.imgList.push(item);
        } else {
          this.downloadData.documentList.push(item);
        }
      });
      this.dialogVisible = true;
    },
    closeBtn(bol) {
      if (bol) {
        this.getList();
      }
      this.dialogVisible = false;
    },
    // 了解更多
    routerPush(ftype) {
      this.$router.push(`/dgsaList?ftype=${ftype}`);
    },
    async getList() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "API_certificate/getDgaFileTypeList"
        );
        if (res.success) {
          if (res.data.length > 4) {
            this.infoData = [[], []];
            res.data.forEach((val, ind) => {
              if (ind < 4) {
                this.infoData[0].push(val);
              } else {
                this.infoData[1].push(val);
              }
            });
          } else {
            this.infoData = res.data.length ? [res.data] : [];
          }
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="less">
.dgsa-home {
  background: #fafafa;
  .das-banner {
    width: 100%;
    padding-bottom: 6%;
    background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/DGSAbanner.png);
    background-size: 100% 100% !important;
    overflow: hidden;
    .dgs-banner-txt {
      width: 1122px;
      margin: 0 auto;
      .banner-txt-title {
        font-size: 39px;
        color: #ffffff;
        line-height: 54px;
        margin-top: 40px;
      }
      .banner-txt-value {
        width: 750px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        margin-top: 12px;
      }
    }
  }
  .dgs-module {
    /deep/ .el-carousel {
      width: 1122px;
      margin: 0 auto;
      margin-top: -72px;
    }
    /deep/ .el-carousel__indicators--outside button {
      background-color: #116bff;
      width: 32px;
      height: 4px;
      border-radius: 7px;
    }
    /deep/ .el-carousel__container {
      height: 140px;
    }
    .module-list {
      display: flex;
      background-color: transparent;
      .module-item {
        width: 249px;
        height: 115px;
        box-shadow: 0px 2px 5px 0px #e5f1fe;
        border-radius: 7px;
        background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/moduleBGWhiter.png);
        background-size: 100% 100% !important;
        .item-title {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          line-height: 22px;
          padding: 0 20px;
          margin-top: 22px;
        }
        .item-value {
          width: 100%;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          padding: 0 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-top: 11px;
        }
        .item-btn {
          padding: 0 12px;
          display: flex;
          align-items: center;
          margin-left: 156px;
          margin-top: 13px;
          color: #116bff;
          span {
            font-size: 12px;
            line-height: 17px;
          }
          i {
            margin-left: 6px;
          }
        }
        &:hover {
          background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/moduleBGBlue.png);
          .item-title {
            color: #ffffff;
          }
          .item-value {
            color: #e5efff;
          }
          .item-btn {
            color: #ffffff;
          }
        }
      }
    }
    .module-title {
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
      margin-top: 30px;
      text-align: center;
    }
    .module-value {
      font-size: 14px;
      color: #515050;
      line-height: 20px;
      margin-top: 10px;
      text-align: center;
    }
  }
  .dgs-serve {
    margin-top: 38px;
    background: #f7faff;
    .serve-list {
      width: 962px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 60px;
      .serve-item {
        width: 250px;
        background: #ffffff;
        border-radius: 9px;
        margin-right: 106px;
        margin-bottom: 86px;
        text-align: center;
        > img {
          margin-top: 37px;
        }
        .setve-horizontal {
          width: 98px;
          height: 6px;
          background: #cce0ff;
          margin: 0 auto;
          margin-top: 35px;
        }
        .setve-title {
          font-size: 20px;
          font-weight: 500;
          color: #27292d;
          line-height: 28px;
          margin-top: -25px;
        }
        .setve-title1{
          margin-top: 10px;
          padding: 0 10px;
          height: 58px;
        }
        .setve-btn {
          background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/DGSAserveBtnBg.png);
          background-size: 100% 100% !important;
          height: 58px;
          margin-top: 10px;
          overflow: hidden;
          > div {
            width: 87px;
            height: 27px;
            border-radius: 2px;
            border: 1px solid #ffffff;
            margin: 0 auto;
            margin-top: 16px;
            font-size: 12px;
            color: #ffffff;
            line-height: 25px;
          }
        }
        &:hover {
          .setve-btn {
            > div {
              background: #ffffff;
              color: #2785fe;
            }
          }
        }
      }
      .serve-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>