<template>
  <div class="download-dialog">
    <el-dialog
      title="下载"
      :visible="dialogVisible"
      @close="closeBtn"
      width="457px"
    >
      <div class="download-type" v-if="downloadData.documentList.length">
        <div>文件</div>
      </div>
      <div class="document-list" v-if="downloadData.documentList.length">
        <div
          class="document-item"
          v-for="(val, ind) in downloadData.documentList"
          :key="ind"
        >
          <div>{{ val.name }}</div>
          <div class="document-btn cursor" @click="downloadResource(val.fileUrl)">
            下载
          </div>
        </div>
      </div>
      <div class="download-type" v-if="downloadData.imgList.length">
        <div>图片</div>
      </div>
      <div class="img-list" v-if="downloadData.imgList.length">
        <div
          class="img-item"
          v-for="(val, ind) in downloadData.imgList"
          :key="ind"
        >
          <el-image
            class="img img-bg"
            :src="val.fileUrl"
            fit="contain"
          ></el-image>
          <div class="document-btn">
            <span class="cursor" @click="downloadResource(val.fileUrl)">下载</span>
          </div>
        </div>
      </div>
      <div
        class="no-data"
        v-if="
          !(downloadData.imgList.length || downloadData.documentList.length)
        "
      >
        暂无数据
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "downloadDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    downloadData: {
      type: Object,
      default: () => {
        return {
          documentList: [],
          imgList: [],
          id: ""
        };
      },
    },
  },
  data() {
    return {
      is_download: false,
    };
  },
  methods: {
    //资源下载
    async downloadResource(url) {
      window.open(url);
      try {
        let params = {
          dga_file_id: this.downloadData.id,
          download: 1,
        };
        let res = await this.$store.dispatch(
          "API_certificate/uploadFileEdit",
          params
        );
        if (res.success) {
          this.is_download = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeBtn() {
      this.$emit("closeBtn", this.is_download);
      this.is_download = false;
    },
  },
};
</script>

<style scoped lang="less">
.download-dialog {
  /deep/ .el-dialog__body {
    padding: 11px 9px;
    background: #ffffff;
  }
  .download-type {
    height: 40px;
    background: #f6f7f9;
    border-radius: 3px;
    padding-top: 12px;
    padding-left: 10px;
    overflow: hidden;
    > div {
      height: 15px;
      border-left: 3px solid #116bff;
      padding-left: 9px;
      font-size: 16px;
      color: #2b2b2a;
      line-height: 15px;
    }
  }
  .document-list {
    margin-top: 20px;
    padding: 0 12px;
    .document-item {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }
  }
  .document-btn {
    color: #116bff;
    flex-shrink: 0;
    padding-left: 20px;
  }
  .img-list {
    margin-top: 20px;
    padding: 0 12px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    .img-item {
      margin-right: 20px;
      margin-bottom: 10px;
      .img-bg {
        background: #f7f8fa;
        width: 104px;
        height: 100px;
      }
      .document-btn {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  .no-data {
    text-align: center;
    padding: 50px 0;
  }
}
</style>